.six-container {
  padding: 2rem;
}

.page-section {
  margin-bottom: 3rem;
}

.headline {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.intro-paragraph {
  font-size: 1.1rem;
  color: #555;
}

.section-container {
  display: flex;
  justify-content: space-between;
}

.section {
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: center;
}

.image-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-image {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.section-paragraph {
  font-size: 1rem;
  color: #666;
  text-align: justify;
}

.link-button {
  padding: 0.6rem 1.2rem;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  margin-top: 1rem;
}

.link-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
  transition: all 0.3s ease;
}

/* Animations */
.animated-slide {
  animation: slide-in 1s ease-in-out;
}

.animated-fade {
  animation: fade-in 1.5s ease-in-out;
}

.animated-bounce {
  animation: bounce 2s infinite;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-container {
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: 100%;
    margin-bottom: 2rem;
  }
}