/* Container styling */
.container {
  padding: 40px;
  text-align: center;
}

/* Headline styling */
.headline {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  font-size: 40px;
}

.sub-headline {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #666;
}

/* Button Group */
.button-group {
  margin-bottom: 40px;
}

.custom-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.custom-button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
}

/* Box section styling */
.box-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}



/* Individual box styling */
.box {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.img-fluid {
  width: 300px;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: transform 0.3s ease-in-out;
}

.box:hover .img-fluid {
  transform: scale(1.05);
}

/* Box description */
.box-description {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

/* Link styling */
.box-link {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.box-link:hover {
  color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .box-section {
    flex-direction: column;
  }
  
  .box {
    margin-bottom: 20px;
  }
}