/* Footer.css */

/* Ensure the footer spans the full width of the page */
.footer {
  background-color: #2b2e4a;
  color: white;
  padding: 40px 0;
  position: relative;
  width: 100%;  /* Full width */
  bottom: 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;  /* Ensure sections are spaced in a row */
  flex-wrap: wrap;  /* Allows wrapping on smaller screens */
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-links {
  flex: 1;  /* Makes each section take equal space */
  margin: 0 15px;
  min-width: 200px;
}

.footer-links h3 {
  color: #ffb700;
  margin-bottom: 20px;
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links ul li a:hover {
  color: #ffb700;
}

/* Footer Bottom Section */
.footer-bottom {
  margin-top: 30px;
  border-top: 1px solid #4c4f6d;
  padding-top: 20px;
  text-align: center;
  font-size: 14px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;  /* Center items in the column layout */
  }

  .footer-links {
    margin-bottom: 20px;  /* Add spacing between sections */
  }
}

