.message-box-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  font-family: 'Arial', sans-serif;
}

.message-box-toggle {
  padding: 12px 25px;
  background-color: #0EA0CC;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.message-box-toggle:hover {
  background-color: #218838;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.message-box {
  margin-top: 10px;
  padding: 20px;
  width: 320px;
  background: linear-gradient(135deg, #fc5c7d, #6a82fb);
  border-radius: 15px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.5s ease-out, fadeIn 0.5s ease-out;
  color: white;
  opacity: 0;
  animation-fill-mode: forwards;
}

.message-box h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

textarea {
  width: 100%;
  height: 80px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 14px;
  resize: none;
  transition: box-shadow 0.3s ease;
}

textarea:focus {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.submit-btn {
  margin-top: 15px;
  padding: 12px 0;
  background-color: #0EA0CC;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0EA0CC;
}

.open .message-box {
  opacity: 1;
  transform: translateY(0);
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}