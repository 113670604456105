/* Container and General Styling */
.help-page {
  padding: 50px;
  background-color: #f9f9f9;
}

/* Dropdown Container */
.dropdown-container {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.dropdown-container h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Always Open Dropdown List */
.service-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.service-item {
  padding: 10px 15px;
  margin: 5px 0;
  cursor: pointer;
  background-color: #f4f4f4;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.service-item:hover {
  background-color: #0EA0CC;
  color: #fff;
}

.service-item.active {
  background-color: #007bff;
  color: #fff;
}

/* Box Styling */
.box {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

#box box-medium .box-description {
  color: 0EA0CC;
}

.box img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
}

.box-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.box-description .abox3{
  color: 0EA0CC;
}

.box-link {
  color: 0EA0CC;
  text-decoration: none;
  transition: color 0.3s ease;
}

.box-link:hover {
  color: 0EA0CC;
}

/* Large Box */
.box-large {
  height: 100%;
}

/* Medium Box */
.box-medium {
  height: 100%;
}

/* Small Box */
.box-small {
  height: 100%;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .box {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .help-page {
    padding: 20px;
  }

  .dropdown-container {
    margin-bottom: 20px;
  }
}

h1{
  text-align: center;
  margin-bottom: 50px;
  font-size: 2.5rem;
  color: #333;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 70px;
  
}

p{
   padding-bottom: 50px;
   text-align: center;
}

/* In your CSS file (e.g., styles.css) */
.stylish-font {
  font-family: 'Georgia, serif';
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: #333;
}