.slider-container {
  max-width: 1200px; /* Maximum width of the slider */
  margin: 0 auto; /* Center the container */
  padding: 40px 20px; /* Padding around the container */
  background-color: #f7f7f7; /* Light background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  position: relative; /* Position relative for button placement */
}

.slider-title {
  font-size: 2.5em; /* Font size for the title */
  margin-bottom: 50px; /* Space below the title */
  color: #333; /* Title color */
  text-align: center; /* Center the title */
}

.slider-wrapper {
  display: flex; /* Flexbox layout */
  align-items: center; /* Center items vertically */
  overflow: hidden; /* Hide overflow for smooth transitions */
  position: relative; /* Position relative for button overlay */
}

.slider-content {
  display: flex; /* Flexbox layout for content */
  flex-direction: column; /* Column layout for image and text */
  align-items: center; /* Center items */
  transition: opacity 0.5s ease; /* Smooth transition for opacity */
  width: 100%; /* Full width */
}

.slider-image {
  width: 200px; /* Width of the image */
  height: 200px; /* Height of the image */
  border-radius: 50%; /* Circular images */
  border: 3px solid #000000; /* Border around the images */
  transition: transform 0.3s ease; /* Smooth scaling */
}

.slider-image:hover {
  transform: scale(1.1); /* Scale effect on hover */
}

.slider-text {
  text-align: center; /* Center the text */
  margin-top: 15px; /* Space above the text */
}

.slider-text h2 {
  font-size: 1.8em; /* Font size for name */
  color: #000000; /* Color for name */
  margin: 5px 0; /* Margin around name */
}

.slider-text p {
  font-size: 1em; /* Font size for role and description */
  color: #666; /* Color for role */
  margin: 5px 0; /* Margin around text */
}

.slider-button {
  background: none; /* No background */
  border: none; /* No border */
  font-size: 2em; /* Size of the button */
  color: #000000; /* Button color */
  cursor: pointer; /* Pointer cursor on hover */
  position: absolute; /* Absolute positioning */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust vertical centering */
  z-index: 10; /* Layer above images */
  transition: color 0.3s ease; /* Smooth color transition */
}

.slider-button:hover {
  color: #0056b3; /* Darker color on hover */
}

.slider-button.prev {
  left: 10px; /* Position for previous button */
}

.slider-button.next {
  right: 10px; /* Position for next button */
}