/* Eleven.css */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.insights-container h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem; /* Adjust heading size as needed */
}

.article-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
  gap: 20px; /* Space between grid items */
  padding: 0 20px; /* Padding for better layout */
}

.article-box {
  background-color: #fff; /* Background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  overflow: hidden; /* Ensures content doesn't overflow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animation for hover effects */
}

.article-box:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.article-image {
  width: 100%; /* Ensures images take full width of the box */
  height: 200px; /* Fixed height for consistent sizing */
  object-fit: cover; /* Maintain aspect ratio */
  transition: transform 0.3s ease; /* Animation for image hover effect */
}

.article-image:hover {
  transform: scale(1.05); /* Slight zoom effect on image hover */
}

.article-box h2 {
  margin: 15px; /* Space around title */
  font-size: 1.5rem; /* Title font size */
}

.article-box p {
  margin: 0 15px 15px; /* Space around description */
  color: #666; /* Description text color */
}