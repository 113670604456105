/* Container Styling */
.container1 {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Text Section */
.text-section1 {
  flex: 1;
  margin-right: 2rem;
}

.headline1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
  animation: slide-in 1.2s ease-in-out;
}

.paragraph1 {
  font-size: 1.1rem;

  color: #666;
  line-height: 1.6;
}

.learn-more-button1 {
  padding: 0.8rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: transform 0.3s ease;
  margin-bottom: 50px;
}

.learn-more-button1:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Image Section */
.image-section1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle1 {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: zoom-in 1.5s ease;
}

/* Animations */
@keyframes slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .content1 {
    flex-direction: column;
    text-align: center;
  }

  .text-section1 {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .circle1 {
    width: 200px;
    height: 200px;
  }
}