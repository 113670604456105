/* Basic Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 20px;
}

.navbar-logo img {
  height: 50px;
  width: auto;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-item {
  color: rgb(20, 149, 223);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  padding: 8px 15px;
}

.nav-item:hover {
  color: #0fa3d0;
}

/* Dropdown styling */
.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #004085;
  padding: 8px;
  text-decoration: none;
  font-size: 14px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  color: #004085;
}

/* Search bar styling */
.search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 5px;
  border: none;
  border-radius: 3px;
  margin-right: 5px;
}

.search-button {
  padding: 5px 10px;
  background-color: #0fa3d0;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0fa3d0;
}