/* Main container layout */
.headline-image-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    background-color: #f9f9f9;
    gap: 10px; /* Reduced gap to eliminate extra space */
}

/* Left-side layout */
.headline-left {
margin-bottom: 50px;
}

.headline-item{
    margin-top: 50px;
}

.animated-header {
    font-size: 28px;
    color: #333;
    margin-bottom: 10px;
    opacity: 0;
    animation: slideInLeft 1s ease-out forwards;
    text-align: left;
}

.animated-text {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    opacity: 0;
    animation: fadeInUp 1.2s ease-out forwards;
    text-align: justify;
}

/* Learn More button positioning */
.learn-more-container {
    margin-top: 20px;
}

.learn-more-btn {
    padding: 12px 24px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    opacity: 0;
    animation: bounceIn 1.5s ease-out forwards;
}

.learn-more-btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Right-side image section */
.headline-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.image-wrapper {
    position: relative;
    width: 350px; /* Adjust image width */
    height: auto;
    overflow: hidden;
    margin-top: 200px;
}

/* Main image animation */
.main-image {
    width: 100%;
    border-radius: 10px;
    animation: fadeInRight 2s ease forwards;
}

/* Overlay image animation */
.overlay-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
    transform: translate(-50%, -50%);
    opacity: 0.8;
    animation: pulse 4s infinite alternate;
}

/* Keyframes for animations */
@keyframes slideInLeft {
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInRight {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.7;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 1;
    }
}

@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    60% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
    .headline-image-section {
        flex-direction: column;
        align-items: center;
    }

    .headline-right {
        margin-top: 30px;
    }

    .image-wrapper {
        width: 100%;
    }

    .main-image, .overlay-image {
        width: 100%;
    }
}