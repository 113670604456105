/* Full page container */
.home-container {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Full-screen background image */
  .background-image {
  
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('../comp/logo file/How-much-should-I-charge-for-medical-billing-services.jpg'); /* Add your image path here */
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Center the image */
    margin-top: 150px;
    margin-bottom: 150px;
  }
  
  /* Bottom rectangle box */
  .bottom-box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    padding: 20px 40px;
    text-align: center;
    width: 800px; /* Adjust width as needed */
    max-width: 500px; /* Optional max width */
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  }
  
  .bottom-box h2 {
    margin: 0;
    font-size: 24px;
    color: #333; /* Dark text color */
  }
  
  .bottom-box p {
    margin-top: 5px;
    font-size: 16px;
    color: #666; /* Lighter text color */
  }

  button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff; /* Button color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease; /* Transition for smooth animation */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial subtle shadow */
  outline: none; /* Remove outline */
}

button:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: translateY(-5px); /* Lift button slightly */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

button:active {
  transform: translateY(2px); /* Slight downshift on click */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Reset shadow on click */
}


.Container {
  padding: 100px 50px;
  max-width: 1500px;
 
  margin: auto;
}

.logo-description-section {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 20px 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 100px;
  transition: all 0.3s ease-in-out;
}

/* Logo Box Styles */
.logo-box {
  flex: 0 0 150px;
  margin-right: 30px;
  position: relative;
  animation: scaleIn 1.5s ease-in-out;
}

/* Animation for logo */
@keyframes scaleIn {
  0% {
      transform: scale(0.5);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

/* Logo Image Styling */
.logo1 {
  width: 500px;
  height: auto;
  display: block;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s;
}

.logo1:hover {
  transform: scale(1.1);
}

/* Description Box Styles */
.description-box {
  flex: 1;
  text-align: left;
}

/* Heading (H1) Styles */
.description {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  transition: color 0.3s ease-in-out;
}

/* Paragraph Styling */
.long-description {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

/* Hover effects */
.logo-description-section:hover .description {
  color: #007BFF; /* Change heading color on hover */
}

.logo-description-section:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Intensify box shadow */
}

.help-page-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.headline {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
}

/* Content Section Styles */
.content-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

/* Dropdown Section Styles */
.dropdown-section {
  width: 45%;
  position: relative;
  text-align: justify;
}

.dropdown-toggle-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
}

.dropdown-menu {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 165px;
  display: flex;
  flex-direction: column-reverse;
}

.dropdown-link {
  padding: 8px 0;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.dropdown-link:hover {
  color: #4CAF50;
}

